var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":"创建调度任务","visible":_vm.createDispatchDialog,"width":"800px","close-on-click-modal":false,"element-loading-background":_vm.loadingBackground,"before-close":_vm.handleClose,"custom-class":"createDispatchDialog"}},[_c('div',{staticClass:"dialog-title"},[_c('span',[_vm._v("已选中"+_vm._s(_vm.itemList.length)+"个安装任务")]),_vm._v(" "),_c('span',[_vm._v("(忽略"+_vm._s(_vm.ignoreNum)+"个不可操作任务项)")])]),_vm._v(" "),_c('el-form',{ref:"dispatchForm",attrs:{"label-width":"120px","model":_vm.dispatchForm,"rules":_vm.dispatchFormRules}},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"第一联系人","prop":"corpContactName"}},[_c('el-input',{attrs:{"placeholder":"第一联系人"},model:{value:(_vm.dispatchForm.corpContactName),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "corpContactName", $$v)},expression:"dispatchForm.corpContactName"}})],1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"第一联系电话","prop":"corpContactPhone"}},[_c('el-input',{attrs:{"placeholder":"第一联系电话"},model:{value:(_vm.dispatchForm.corpContactPhone),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "corpContactPhone", $$v)},expression:"dispatchForm.corpContactPhone"}})],1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[(_vm.basicInfo.taskSource == 3 || _vm.basicInfo.taskSource == 5)?_c('el-form-item',{attrs:{"label":"第二联系人","prop":"contactName","rules":{
              required: true,
              message: '请输入第二联系人',
              trigger: ['blur', 'change']
            }}},[_c('el-input',{attrs:{"aria-placeholder":"第二联系人"},model:{value:(_vm.dispatchForm.contactName),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "contactName", $$v)},expression:"dispatchForm.contactName"}})],1):_c('el-form-item',{attrs:{"label":"第二联系人","prop":"contactId","rules":{
              required: true,
              message: '请选择第二联系人',
              trigger: ['blur', 'change']
            }}},[_c('div',{staticClass:"select-content"},[_c('e6-vr-select',{staticStyle:{"width":"200px"},attrs:{"data":_vm.contactList,"placeholder":"第二联系人","title":"","props":{
                  id: 'contactId',
                  label: 'contactName'
                }},on:{"change":_vm.handleContact},model:{value:(_vm.dispatchForm.contactId),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "contactId", $$v)},expression:"dispatchForm.contactId"}}),_vm._v(" "),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"text"},on:{"click":_vm.addConcat}},[_vm._v("\n                添加联系人\n              ")])],1)])],1),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"实施联系电话","prop":"contactPhone"}},[_c('el-input',{attrs:{"aria-placeholder":"实施联系电话"},model:{value:(_vm.dispatchForm.contactPhone),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "contactPhone", $$v)},expression:"dispatchForm.contactPhone"}})],1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"省市区地址","prop":"defaultCity"}},[_c('e6-vr-select',{staticStyle:{"width":"300px"},attrs:{"data":_vm.addressList,"placeholder":"联系地址","title":"联系地址","virtual":"","clearable":"","is-title":true,"remote":"","popper-append-to-body":false,"props":{
                id: 'fullName',
                label: 'fullName'
              }},on:{"filterChange":_vm.handleLoadAddressFilter,"change":_vm.handleCitySelect},model:{value:(_vm.dispatchForm.defaultCity),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "defaultCity", $$v)},expression:"dispatchForm.defaultCity"}})],1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"详细地址","prop":"insAddress"}},[_c('el-input',{attrs:{"placeholder":"详细地址"},model:{value:(_vm.dispatchForm.insAddress),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "insAddress", $$v)},expression:"dispatchForm.insAddress"}})],1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"预约上门时间","prop":"taskArrangedate"}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"选择日期","value-format":"timestamp","popper-class":"special","picker-options":_vm.pickerOptions1},model:{value:(_vm.dispatchForm.taskArrangedate),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "taskArrangedate", $$v)},expression:"dispatchForm.taskArrangedate"}})],1)],1),_vm._v(" "),(
            _vm.basicInfo.taskFirstClass == 1 &&
              _vm.basicInfo.taskSource == 2 &&
              (_vm.basicInfo.sceneType == 1 || _vm.basicInfo.sceneType == 4)
          )?_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"设备来源","prop":"equSourceList"}},[_c('el-checkbox-group',{model:{value:(_vm.dispatchForm.equSourceList),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "equSourceList", $$v)},expression:"dispatchForm.equSourceList"}},[_c('el-checkbox',{attrs:{"label":1}},[_vm._v("客户名下设备")]),_vm._v(" "),_c('el-checkbox',{attrs:{"label":2}},[_vm._v("工程师名下设备")])],1)],1)],1):_vm._e(),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"调度备注","prop":"remark"}},[_c('el-input',{attrs:{"placeholder":"调度备注","maxlength":"100"},model:{value:(_vm.dispatchForm.remark),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "remark", $$v)},expression:"dispatchForm.remark"}})],1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"实施原因","prop":"implementationReason"}},[_c('el-input',{attrs:{"placeholder":"实施原因","maxlength":"100"},model:{value:(_vm.dispatchForm.implementationReason),callback:function ($$v) {_vm.$set(_vm.dispatchForm, "implementationReason", $$v)},expression:"dispatchForm.implementationReason"}})],1)],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.handleClose}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" 确定")])],1)],1),_vm._v(" "),_c('add-concat-dialog',{attrs:{"addContactVisible":_vm.addContactVisible,"corpId":_vm.customerId,"corpName":_vm.basicInfo.customerName,"isNeedReq":true},on:{"handleClose":_vm.closeDialog,"updateList":_vm.getContactList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }