<!--
 * @Description: 创建调度任务
 * @Author: ChenXueLin
 * @Date: 2021-08-30 09:11:35
 * @LastEditTime: 2023-03-20 17:14:52
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-dialog
      v-dialogDrag
      title="创建调度任务"
      :visible="createDispatchDialog"
      width="800px"
      v-loading="loading"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="createDispatchDialog"
    >
      <div class="dialog-title">
        <span>已选中{{ itemList.length }}个安装任务</span>
        <span>(忽略{{ ignoreNum }}个不可操作任务项)</span>
      </div>
      <el-form
        label-width="120px"
        ref="dispatchForm"
        :model="dispatchForm"
        :rules="dispatchFormRules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="第一联系人" prop="corpContactName">
              <el-input
                placeholder="第一联系人"
                v-model="dispatchForm.corpContactName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第一联系电话" prop="corpContactPhone">
              <el-input
                placeholder="第一联系电话"
                v-model="dispatchForm.corpContactPhone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-if="basicInfo.taskSource == 3 || basicInfo.taskSource == 5"
              label="第二联系人"
              prop="contactName"
              :rules="{
                required: true,
                message: '请输入第二联系人',
                trigger: ['blur', 'change']
              }"
            >
              <el-input
                aria-placeholder="第二联系人"
                v-model="dispatchForm.contactName"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-else
              label="第二联系人"
              prop="contactId"
              :rules="{
                required: true,
                message: '请选择第二联系人',
                trigger: ['blur', 'change']
              }"
            >
              <div class="select-content">
                <e6-vr-select
                  style="width:200px"
                  v-model="dispatchForm.contactId"
                  :data="contactList"
                  placeholder="第二联系人"
                  title=""
                  :props="{
                    id: 'contactId',
                    label: 'contactName'
                  }"
                  @change="handleContact"
                ></e6-vr-select>
                <el-button
                  type="text"
                  style="margin-left: 10px"
                  @click="addConcat"
                >
                  添加联系人
                </el-button>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实施联系电话" prop="contactPhone">
              <el-input
                aria-placeholder="实施联系电话"
                v-model="dispatchForm.contactPhone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="省市区地址" prop="defaultCity">
              <e6-vr-select
                style="width:300px"
                v-model="dispatchForm.defaultCity"
                :data="addressList"
                placeholder="联系地址"
                title="联系地址"
                virtual
                clearable
                :is-title="true"
                remote
                :popper-append-to-body="false"
                @filterChange="handleLoadAddressFilter"
                @change="handleCitySelect"
                :props="{
                  id: 'fullName',
                  label: 'fullName'
                }"
              ></e6-vr-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="详细地址" prop="insAddress">
              <el-input
                placeholder="详细地址"
                v-model="dispatchForm.insAddress"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预约上门时间" prop="taskArrangedate">
              <el-date-picker
                v-model="dispatchForm.taskArrangedate"
                type="datetime"
                placeholder="选择日期"
                value-format="timestamp"
                popper-class="special"
                :picker-options="pickerOptions1"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="
              basicInfo.taskFirstClass == 1 &&
                basicInfo.taskSource == 2 &&
                (basicInfo.sceneType == 1 || basicInfo.sceneType == 4)
            "
          >
            <el-form-item label="设备来源" prop="equSourceList">
              <el-checkbox-group v-model="dispatchForm.equSourceList">
                <el-checkbox :label="1">客户名下设备</el-checkbox>
                <el-checkbox :label="2">工程师名下设备</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="调度备注" prop="remark">
              <el-input
                placeholder="调度备注"
                v-model="dispatchForm.remark"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实施原因" prop="implementationReason">
              <el-input
                placeholder="实施原因"
                v-model="dispatchForm.implementationReason"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit"> 确定</el-button>
      </div>
    </el-dialog>
    <!-- 添加联系人弹框 -->
    <add-concat-dialog
      :addContactVisible="addContactVisible"
      :corpId="customerId"
      :corpName="basicInfo.customerName"
      @handleClose="closeDialog"
      @updateList="getContactList"
      :isNeedReq="true"
    ></add-concat-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { telAndMobileValid } from "@/utils/validate";
import { createPatchTask, getContactList, getAddressData } from "@/api";
import { printError } from "@/utils/util";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import eventBus from "@/bus";
export default {
  name: "createDispatch",
  components: { AddConcatDialog },
  data() {
    return {
      loading: false,
      addressList: [],
      contactList: [], //客户联系人下拉框
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        }
      },
      dispatchForm: {
        corpContactPhone: "",
        corpContactName: "",
        contactId: "",
        contactName: "", //联系人
        contactPhone: "", //联系人电话
        insAddress: "",
        provinceName: "",
        province: "",
        city: "",
        cityName: "",
        insCountyName: "",
        insCounty: "",
        streetName: "",
        street: "",
        defaultCity: "", //默认地址
        taskArrangedate: "",
        remark: "",
        equSourceList: [],
        implementationReason: ""
      },
      dispatchFormRules: {
        corpContactName: [
          {
            required: true,
            message: "请输入第一联系人",
            trigger: ["blur", "change"]
          }
        ],
        corpContactPhone: telAndMobileValid.required({
          requiredMsg: "请输入第一联系电话",
          message: "号码格式不正确",
          trigger: ["blur", "change"]
        }),
        contactId: [
          {
            required: true,
            message: "请选择第二联系人",
            trigger: ["blur", "change"]
          }
        ],
        contactPhone: telAndMobileValid.required({
          requiredMsg: "请输入手机号码",
          message: "号码格式不正确",
          trigger: ["blur", "change"]
        }),
        defaultCity: [
          {
            required: true,
            message: "请选择省市区街道",
            trigger: ["blur", "change"]
          }
        ],
        insAddress: [
          {
            required: true,
            message: "请填写详细地址",
            trigger: ["blur", "change"]
          }
        ],
        taskArrangedate: [
          {
            required: true,
            message: "请选择预约上门时间",
            trigger: ["blur", "change"]
          }
        ],
        equSourceList: [
          {
            required: true,
            message: "请选择设备来源",
            trigger: ["blur", "change"]
          }
        ]
      },
      addContactVisible: false //添加联系人
    };
  },
  props: [
    "createDispatchDialog",
    "itemIdList",
    "taskId",
    "customerId",
    "itemList",
    "basicInfo"
  ],

  mixins: [base],
  computed: {
    ignoreNum() {
      let num = 0;
      //创建调度任务，只能操作未分派
      num = this.itemList.filter(item => {
        return item.itemStatusCode != 1;
      }).length;
      return num;
    }
  },
  watch: {
    createDispatchDialog(val) {
      if (val) {
        let taskSource = this.basicInfo.taskSource;
        // let itemInfo = taskSource == 3 ? this.itemList[0] : this.basicInfo;
        this.dispatchForm.taskArrangedate = this.basicInfo.expactTimeStamp;
        this.dispatchForm.equSourceList =
          this.basicInfo.taskFirstClass == 1 &&
          taskSource == 2 &&
          this.basicInfo.equSourceList &&
          (this.basicInfo.sceneType == 1 || this.basicInfo.sceneType == 4)
            ? this.basicInfo.equSourceList
            : [];
        if (
          (taskSource == 3 || taskSource == 5) &&
          this.basicInfo.sourceOrganization == 2
        ) {
          this.dispatchForm.contactPhone = this.basicInfo.contactPhone;
          this.dispatchForm.contactName = this.basicInfo.contact;
        } else {
          this.getContactList();
          if (this.basicInfo.contactPhone) {
            this.dispatchForm.contactPhone = this.basicInfo.contactPhone;
            this.dispatchForm.contactName = this.basicInfo.contact;
          }
        }
        //省
        if (this.basicInfo.insCountyName) {
          this.dispatchForm.provinceName = this.basicInfo.provinceName;
          this.dispatchForm.province = this.basicInfo.provinceId;
          //市
          this.dispatchForm.cityName = this.basicInfo.cityName;
          this.dispatchForm.city = this.basicInfo.cityId;
          //区
          this.dispatchForm.insCountyName = this.basicInfo.insCountyName;
          this.dispatchForm.insCounty = this.basicInfo.insCountyId;
          //街道
          this.dispatchForm.streetName = this.basicInfo.streetName;
          this.dispatchForm.street = this.basicInfo.streetId;
          //详细地址
          this.dispatchForm.insAddress = this.basicInfo.address2;
          this.dispatchForm.corpContactName = this.basicInfo.corpContactName;
          this.dispatchForm.corpContactPhone = this.basicInfo.corpContactPhone;
          this.dispatchForm.implementationReason = this.basicInfo.remark;
          //默认地址
          this.dispatchForm.defaultCity =
            this.basicInfo.provinceName +
            this.basicInfo.cityName +
            this.basicInfo.insCountyName +
            this.basicInfo.streetName;
          this.loadAddressData(this.dispatchForm.defaultCity);
        }
      }
    }
  },
  methods: {
    //联系人选择
    handleContact() {
      let concatInfo = this.contactList.find(item => {
        return item.contactId == this.dispatchForm.contactId;
      });
      if (concatInfo && concatInfo.phone1) {
        this.dispatchForm.contactPhone = concatInfo.phone1;
      }
      if (concatInfo.addressList.length && concatInfo.addressList[0].areaId) {
        //省
        this.dispatchForm.provinceName = concatInfo.addressList[0].provinceName;
        this.dispatchForm.province = concatInfo.addressList[0].provinceId;
        //市
        this.dispatchForm.cityName = concatInfo.addressList[0].cityName;
        this.dispatchForm.city = concatInfo.addressList[0].cityId;
        //区
        this.dispatchForm.insCountyName = concatInfo.addressList[0].areaName;
        this.dispatchForm.insCounty = concatInfo.addressList[0].areaId;
        //街道
        this.dispatchForm.streetName = concatInfo.addressList[0].streetName;
        this.dispatchForm.street = concatInfo.addressList[0].streetId;
        //详细地址
        this.dispatchForm.insAddress = concatInfo.addressList[0].address;
        //默认地址
        this.dispatchForm.defaultCity =
          concatInfo.addressList[0].provinceName +
          concatInfo.addressList[0].cityName +
          concatInfo.addressList[0].areaName +
          concatInfo.addressList[0].streetName;
        this.loadAddressData(this.dispatchForm.defaultCity);
      } else {
        this.resetAddress();
      }
    },
    //重置地址
    resetAddress() {
      this.dispatchForm.defaultCity = "";
      this.dispatchForm.province = "";
      this.dispatchForm.provinceName = "";
      this.dispatchForm.city = "";
      this.dispatchForm.cityName = "";
      this.dispatchForm.insCounty = "";
      this.dispatchForm.insCountyName = "";
      this.dispatchForm.streetId = "";
      this.dispatchForm.streetName = "";
      this.dispatchForm.insAddress = "";
      this.dispatchForm.equSourceList = [];
    },
    // 处理城市选择
    handleCitySelect(select, node) {
      let val = _.cloneDeep(node);
      this.dispatchForm.province = val.provinceCode;
      this.dispatchForm.provinceName = val.provinceName;
      this.dispatchForm.city = val.cityCode;
      this.dispatchForm.cityName = val.cityName;
      this.dispatchForm.insCounty = val.countyCode;
      this.dispatchForm.insCountyName = val.countyName;
      this.dispatchForm.street = val.streetCode;
      this.dispatchForm.streetName = val.streetName;
      this.dispatchForm.defaultCity = val.fullName;
    },
    handleLoadAddressFilter: _.debounce(async function(val) {
      if (val) {
        this.resetAddress();
        this.loadAddressData(val);
      }
    }, 300),
    //加载地址列表
    async loadAddressData(val = "") {
      try {
        let res = await getAddressData({
          addressName: val
        });
        //获取地址下拉框
        this.addressList = this.getFreezeResponse(res, {
          path: "data"
        });
        if (!this.addressList.length) {
          this.resetAddress();
        } else {
          if (this.dispatchForm.defaultCity && !this.dispatchForm.insCountyId) {
            let addressInfo = this.addressList.find(item => {
              return item.fullName == this.dispatchForm.defaultCity;
            });
            this.dispatchForm.province = addressInfo.provinceCode;
            this.dispatchForm.provinceName = addressInfo.provinceName;
            this.dispatchForm.city = addressInfo.cityCode;
            this.dispatchForm.cityName = addressInfo.cityName;
            this.dispatchForm.insCounty = addressInfo.countyCode;
            this.dispatchForm.insCountyName = addressInfo.countyName;
            this.dispatchForm.streetId = addressInfo.streetCode;
            this.dispatchForm.streetName = addressInfo.streetName;
          }
        }
      } catch (error) {
        printError(error);
      }
    },
    //获取联系人下拉框
    async getContactList() {
      try {
        this.loading = true;
        let res = await getContactList({ id: this.customerId });
        //客户名称下拉框
        this.contactList = this.getFreezeResponse(res, {
          path: "data"
        });
        let contactInfo = this.contactList.find(item => {
          return item.contactName == this.basicInfo.contact;
        });
        if (contactInfo && contactInfo.contactName) {
          this.dispatchForm.contactId = contactInfo.contactId;
        }
        //默认地址
        if (this.basicInfo.insCountyName) {
          this.dispatchForm.defaultCity =
            this.basicInfo.provinceName +
            this.basicInfo.cityName +
            this.basicInfo.insCountyName +
            this.basicInfo.streetName;
          this.dispatchForm.province = this.basicInfo.provinceId; //省
          this.dispatchForm.provinceName = this.basicInfo.provinceName;
          this.dispatchForm.city = this.basicInfo.cityId; //市
          this.dispatchForm.cityName = this.basicInfo.cityName;
          this.dispatchForm.insCounty = this.basicInfo.insCountyId; //区
          this.dispatchForm.insCountyName = this.basicInfo.insCountyName;
          this.dispatchForm.street = this.basicInfo.provinceId; //街道
          this.dispatchForm.streetName = this.basicInfo.streetName; //街道
          this.dispatchForm.insAddress = this.basicInfo.address2;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击确定新增
    handleSubmit() {
      this.$refs.dispatchForm.validate(valid => {
        if (valid) {
          // if (!this.dispatchForm.insCounty) {
          //   this.$message.warning("地址请至少选择到区县");
          //   return;
          // }
          //联系人名字
          if (
            this.basicInfo.taskSource != 3 &&
            this.basicInfo.taskSource != 5
          ) {
            this.dispatchForm.contactName = this.filterSelect(
              this.contactList,
              "contactId",
              this.dispatchForm.contactId
            ).contactName;
          }

          this.createPatchTask();
        }
      });
    },
    //创建调度任务
    async createPatchTask() {
      try {
        this.loading = true;
        let res = await createPatchTask({
          ...this.dispatchForm,
          itemIdList: this.itemIdList,
          taskId: this.taskId
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.$emit("refresh");
          this.handleClose(true);
          eventBus.$emit("refreshTaskDetail");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.dispatchForm = {
        contactId: "",
        contactName: "", //联系人
        contactPhone: "", //联系人电话
        insAddress: "",
        taskArrangedate: "",
        remark: "",
        province: "",
        provinceName: "",
        city: "",
        cityName: "",
        defaultCity: "",
        insCountyName: "",
        equSourceList: [],
        corpContactPhone: "",
        corpContactName: "",
        implementationReason: ""
      };
      this.$refs.dispatchForm.resetFields();
      this.$emit("handleClose", "createDispatchDialog");
    },
    addConcat() {
      this.addContactVisible = true;
    },
    //关闭添加联系人弹框
    closeDialog() {
      this.addContactVisible = false;
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .dialog-title {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 35px;
    box-sizing: border-box;
    & > span {
      &:nth-child(1) {
        font-size: 14px;
        color: #646466;
      }
      &:nth-child(2) {
        color: #a3a7aa;
        font-size: 12px;
      }
    }
  }
  .city-input {
    width: 260px !important;
  }
  .el-select {
    .el-input {
      width: 200px;
    }
  }
  .el-input {
    width: 260px;
  }
}
/deep/.edit-address {
  .el-form-item__label {
    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
</style>
